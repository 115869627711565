import { guard } from 'utils/general';

/* ----------------------------General Constants---------------------------- */
/* ---------------The constants that generally use everywhere--------------- */

// Date format
export const DATE_FORMAT = 'YYYY-MM-DD';
export const FORMAL_DATE_FORMAT = 'DD-MM-YYYY';
export const MONTH_FORMAT = `YYYY-MM`;
export const DATE_DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const TIME_FORMAT = 'HH:mm';

// Form Label
export const LABEL_SIZE_XXLARGE = 'xxlarge';
export const LABEL_SIZE_XLARGE = 'xlarge';
export const LABEL_SIZE_LARGE = 'large';
export const LABEL_SIZE_REGULAR = 'regular';
export const LABEL_SIZE_SMALL = 'small';

// Regex
export const REGEX_NUMBER_N_DOT = /[^0-9.]/g;
export const CONTACT_NUMBER_REGEX = /^[+0-9][-\s0-9]*$/;
export const EMAIL_ADDRESS_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/;
export const PRICE_REGEX = /^\d*(\.\d{0,2})?$/;
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
export const USERNAME_REGEX = /^[a-zA-Z0-9._-]{6,20}$/;
export const NRIC_REGEX = /^[0-9]{12}$/;
export const NO_WHITESPACE_REGEX = /^\S*$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]*$/;
export const URL_REGEX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

// Toggle Radio Buttons
export const constructGeneralToggleSelection = () => {
  return [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];
};

export const FORM_OTHERS_CODE = 'others';

// Roles
// NOTES: This contants will need be sync with BE constants. Currently we need to hardcode both places
export const ROLE_RESERVATION_OPERATOR = 'reservationOperator';
export const ROLE_ADMIN = 'admin';
export const ROLE_SUPER_ADMIN = 'superAdmin';
export const ROLE_MDEC_ADMIN = 'mdecAdmin';

/* ----------------------------Specific Constants---------------------------- */
/* ---------------The constants that generally use in one page--------------- */

/* ----------------------------Constants functions--------------------------- */
/* -------------Function that related and only use by constant--------------- */
export const constructConstantLabel = (constants, text, fallbackValue) => {
  const foundConst = constants.find(type => type.value === text);
  return guard(() => foundConst.label, fallbackValue);
};

export const getConstantLabelFromCode = (constant, codeToFind, codeKey = 'code', labelKey = 'label') => {
  if (!codeToFind) {
    return '-';
  }
  const foundConstant = Object.values(constant).find(constant => constant[codeKey] === codeToFind);

  if (!foundConstant) {
    return codeToFind;
  }
  return foundConstant[labelKey];
};

// IMAGE UPLOAD FOLDERS
export const FOLDER = {
  partner: 'partners'
};
