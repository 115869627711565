import { ROLE_ADMIN, ROLE_MDEC_ADMIN, ROLE_RESERVATION_OPERATOR, ROLE_SUPER_ADMIN } from 'utils/constants';
const getRoute = (path, role) => ({ path, role });

export const buildBaseUri = () => '/';

export const getMyProfileRoute = () => getRoute(`/my-profile`, ROLE_RESERVATION_OPERATOR);
export const getResetPasswordRoute = () => getRoute(`/reset-password`, ROLE_RESERVATION_OPERATOR);

/** -------------------------------------Error Routes------------------------------------- */
export const get403Route = () => getRoute('/403');
export const get404Route = () => getRoute('/404');

/** -------------------------------------External Routes------------------------------------- */
export const getLoginRoute = () => getRoute('/login');

/** -------------------------------------Internal Routes------------------------------------- */
export const getOverviewRoute = () => getRoute('/overview', ROLE_RESERVATION_OPERATOR);

export const getHostsRoute = () => getRoute('/hosts', ROLE_ADMIN);
export const getNewHostRoute = () => getRoute('/hosts/new', ROLE_ADMIN);
export const getHostDetailRoute = (id = ':id') => getRoute(`/hosts/${id}`, ROLE_ADMIN);

export const getPropertiesRoute = () => getRoute('/properties', ROLE_ADMIN);
export const getNewPropertyRoute = () => getRoute('/properties/new', ROLE_ADMIN);
export const getPropertyDetailsRoute = (listingId = ':listingId') => getRoute(`/properties/${listingId}`, ROLE_ADMIN);

export const getBookingsRoute = () => getRoute('/bookings', ROLE_RESERVATION_OPERATOR);
export const getNewBookingRoute = () => getRoute(`/bookings/new`, ROLE_RESERVATION_OPERATOR);
export const getBookingDetailRoute = (id = ':id') => getRoute(`/bookings/${id}`, ROLE_RESERVATION_OPERATOR);

export const getPackageOrdersRoute = () => getRoute('/package-orders', ROLE_RESERVATION_OPERATOR);
export const getNewPackageOrderRoute = () => getRoute('/package-orders/new', ROLE_RESERVATION_OPERATOR);
export const getPackageOrderDetailRoute = (id = ':id') => getRoute(`/package-orders/${id}`, ROLE_RESERVATION_OPERATOR);

export const getUsersRoute = () => getRoute('/users', ROLE_SUPER_ADMIN);
export const getNewUserRoute = () => getRoute('/users/new', ROLE_SUPER_ADMIN);
export const getUserDetailsRoute = (id = ':id') => getRoute(`/users/${id}`, ROLE_SUPER_ADMIN);

export const getMembersRoute = () => getRoute('/members', ROLE_MDEC_ADMIN);
export const getNewMemberRoute = () => getRoute('/members/new', ROLE_MDEC_ADMIN);
export const getMemberDetailsRoute = (id = ':id') => getRoute(`/members/${id}`, ROLE_MDEC_ADMIN);

export const getPromotionsRoute = () => getRoute('/promotions', ROLE_ADMIN);
export const getNewPromotionRoute = () => getRoute('/promotions/new', ROLE_ADMIN);
export const getPromotionDetailsRoute = (id = ':id') => getRoute(`/promotions/${id}`, ROLE_ADMIN);

export const getQRCodeCheckInRoute = () => getRoute('/qr-code-check-in', ROLE_ADMIN);

export const getNomadLocationRoute = () => getRoute('/nomad-location', ROLE_ADMIN);

export const getPartnersRoute = () => getRoute('/partners', ROLE_ADMIN);
export const getNewPartnerRoute = () => getRoute('/partners/new', ROLE_ADMIN);
export const getPartnerDetailsRoute = (partnerId = ':partnerId') => getRoute(`/partners/${partnerId}`, ROLE_ADMIN);

export const getVouchersRoute = () => getRoute('/vouchers', ROLE_ADMIN);
export const getNewVoucherRoute = () => getRoute('/vouchers/new', ROLE_ADMIN);
export const getVoucherDetailsRoute = (voucherId = ':voucherId') => getRoute(`/vouchers/${voucherId}`, ROLE_ADMIN);
// getVoucherDetailsRoute
