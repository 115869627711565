import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Table } from 'antd';
import { uploadVoucherCsv, useGetPaginatedVouchers } from 'apis/voucher';
import Card from 'components/Card/Card';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';
import { useFetchConstant } from 'hooks/constants';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getLabelOfConstant } from 'utils/general';
import { getNewVoucherRoute, getPartnerDetailsRoute, getVoucherDetailsRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterRadio, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

const constructColumns = voucherStatuses => [
  {
    ...constructColumn('Name', 'name'),
    ...constructColumnFilterSearch('name', 'Search voucher'),
    render: (text, record) => {
      return <Link to={getVoucherDetailsRoute(record._id).path}>{text}</Link>;
    }
  },
  {
    ...constructColumn('Partner', 'partnerName', { hasSorter: true }),
    ...constructColumnFilterSearch('partnerName', 'Search partner'),
    render: (text, record) => <Link to={getPartnerDetailsRoute(record.partnerId).path}>{text}</Link>
  },
  {
    ...constructColumn('Voucher URL', 'voucherUrl'),
    ...constructColumnFilterSearch('voucherUrl', 'Search Voucher URL'),
    render: (text, record) => {
      return (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      );
    }
  },
  {
    ...constructColumn('Start Date', 'startDate', { isDate: true, hasSorter: true })
  },
  {
    ...constructColumn('End Date', 'endDate', { isDate: true, hasSorter: true })
  },
  {
    ...constructColumn('Status', 'status'),
    ...constructColumnFilterRadio('status', voucherStatuses),
    render: status => getLabelOfConstant(status, voucherStatuses) || '-'
  }
];

const Vouchers = () => {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const { isLoading: voucherDataLoading, paginatedData: vouchers, total } = useGetPaginatedVouchers(query);
  const { isLoading: statusLoading, selection: voucherStatuses } = useFetchConstant('voucherStatuses');

  const isLoading = voucherDataLoading || statusLoading;

  const voucherDatas = useRef([]);
  useEffect(() => {
    if (vouchers) {
      voucherDatas.current = vouchers.map(val => {
        return {
          _id: val._id,
          name: val.name,
          voucherUrl: val.voucherUrl,
          partnerId: val.partnerId,
          partnerName: val.partnerDetails.name,
          startDate: val.startDate,
          endDate: val.endDate,
          status: val.status
        };
      });
    }
  }, [vouchers]);

  const fileInputRef = useRef(null);
  const handleUploadBtnClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadedFile = event => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();

    formData.append('file', selectedFile, selectedFile.name);

    uploadVoucherCsv(formData)
      .then(res => {
        message.success(`Vouchers has been successfully uploaded.`);
      })
      .catch(err => {
        message.error(`Voucher upload error: ${err}`);
      })
      .finally((event.target.value = ''));
  };

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Vouchers</Title>
          </Col>
          <Col>
            <Row gutter={8}>
              <Col>
                <Button icon={<UploadOutlined />} onClick={handleUploadBtnClick}>
                  Upload CSV
                </Button>
                <input type="file" onChange={handleUploadedFile} style={{ display: 'none' }} ref={fileInputRef} />
              </Col>
              <Col>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(getNewVoucherRoute().path)}>
                  Add new voucher
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={voucherDatas.current}
        columns={constructColumns(voucherStatuses)}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default Vouchers;
