import React from 'react';
import { Col } from 'antd';

import Title from 'components/Title/Title';

import { SetupContainerCard, StyledTitle, RoomArrangementContainer } from './SetupCard.styles';

import RowFormCounter from '../RowFormCounter/RowFormCounter';
import FormBedRooms from '../FormBedrooms/FormBedrooms';

const FIELD_NAME_NO_OF_LIVING_ROOMS = 'livingrooms';
const FIELD_NAME_NO_OF_BATH_ROOMS = 'bathrooms';
const FIELD_NAME_BEDROOMS = 'bedrooms';

const SetupCard = ({ canEdit, form, bedTypes }) => {
  return (
    <Col>
      <SetupContainerCard title="Room Setup">
        <StyledTitle hasRequiredErrorMessage>
          <Title type="s" marginBottom="16px">
            Number of Rooms
          </Title>
        </StyledTitle>
        <RowFormCounter disable={!canEdit} form={form} name={FIELD_NAME_NO_OF_LIVING_ROOMS} label="Living Rooms" />
        <RowFormCounter disable={!canEdit} form={form} name={FIELD_NAME_NO_OF_BATH_ROOMS} label="Bathrooms" />
        <StyledTitle hasRequiredErrorMessage>
          <Title type="s" marginBottom="16px">
            Sleep Arrangements
          </Title>
        </StyledTitle>
        <RoomArrangementContainer type="flex" justify="space-between">
          <FormBedRooms canEdit={canEdit} form={form} name={FIELD_NAME_BEDROOMS} bedTypes={bedTypes} />
        </RoomArrangementContainer>
      </SetupContainerCard>
    </Col>
  );
};

export default SetupCard;
