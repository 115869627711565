import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Card from 'components/Card/Card';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';

import { useGetPaginatedPromotions } from 'apis/promotion';
import { useFetchConstant } from 'hooks/constants';

import { constructDisplayPrice, constructDisplayPercentage, getLabelOfConstant, guard } from 'utils/general';
import { getNewPromotionRoute, getPromotionDetailsRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';

const constructColumns = (promotionTypes, promotionStatuses, handleOnEditPromotion) => {
  return [
    {
      ...constructColumn('Promotion code', 'code', { hasSorter: true, width: '15%' }),
      ...constructColumnFilterSearch('code', 'Search Promotion Code'),
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => handleOnEditPromotion(record._id)}>
            {text}
          </Button>
        );
      }
    },
    {
      ...constructColumn('Promotion Name', 'name', { hasSorter: true, width: '25%' }),
      ...constructColumnFilterSearch('name', 'Search Promotion Name')
    },
    {
      ...constructColumn('Type', 'type'),
      ...constructColumnFilterRadio('type', promotionTypes),
      render: (text, record) => {
        const promotionTypeObject = promotionTypes.find(type => type.code === record.type);
        return guard(() => promotionTypeObject.label);
      }
    },
    {
      ...constructColumn('Amount', 'amount', { hasSorter: true }),
      render: (text, record) => {
        const promotionTypeObject = promotionTypes.find(type => type.code === record.type);
        return guard(() => (promotionTypeObject.isPercentage ? constructDisplayPercentage(text, true) : constructDisplayPrice(text)), '');
      }
    },
    {
      ...constructColumn('Total Redeemed', 'redemptionCount', { hasSorter: true })
    },
    {
      ...constructColumn('Status', 'status'),
      ...constructColumnFilterRadio('status', promotionStatuses),
      render: status => getLabelOfConstant(status, promotionStatuses)
    },
    {
      ...constructColumn('Start Date', 'startDate', { isDate: true, hasSorter: true })
    },
    {
      ...constructColumn('End Date', 'endDate', { isDate: true, hasSorter: true })
    }
  ];
};

const usePromotions = query => {
  const { isLoading: isPromotionsLoading, paginatedData: promotions, total } = useGetPaginatedPromotions(query);

  const formattedPromotions = useMemo(() => {
    return !isPromotionsLoading
      ? promotions.map(promotion => ({
          _id: promotion._id,
          code: promotion.code,
          name: promotion.name,
          type: promotion.type,
          amount: promotion.amount,
          redemptionCount: guard(() => promotion.redemptionCount, 0),
          status: promotion.status,
          startDate: promotion.startDate,
          endDate: promotion.endDate
        }))
      : [];
  }, [isPromotionsLoading, promotions]);

  return { isPromotionsLoading, promotions: formattedPromotions, total };
};

const Promotions = () => {
  const [query, setQuery] = useState({});
  const history = useHistory();

  const { isLoading: isPromotionStatusesLoading, selection: promotionStatuses } = useFetchConstant('promotionStatuses');
  const { isLoading: isPromotionTypesLoading, selection: promotionTypes } = useFetchConstant('promotionTypes');

  const { isPromotionsLoading, promotions, total } = usePromotions(query);

  const isLoading = useMemo(() => isPromotionStatusesLoading || isPromotionTypesLoading || isPromotionsLoading, [
    isPromotionStatusesLoading,
    isPromotionTypesLoading,
    isPromotionsLoading
  ]);

  const handleOnCreateNew = () => {
    history.push(getNewPromotionRoute().path);
  };

  const handleOnEditPromotion = promotionId => {
    history.push(getPromotionDetailsRoute(promotionId).path);
  };

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Promotions</Title>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleOnCreateNew}>
              Add new promotion
            </Button>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={promotions}
        columns={constructColumns(promotionTypes, promotionStatuses, handleOnEditPromotion)}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default Promotions;
