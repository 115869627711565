import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip, Input } from 'antd';
import { CameraOutlined, DeleteOutlined } from '@ant-design/icons';

import styles from './PhotoDisplayCard.module.css';

const MakePrimaryAction = ({ onClick }) => (
  <Tooltip placement="top" title="Make primary photo">
    <span className={styles.actionContainer} onClick={onClick}>
      <CameraOutlined />
    </span>
  </Tooltip>
);

const DeleteAction = ({ onClick }) => (
  <span className={styles.actionContainer} onClick={onClick}>
    <DeleteOutlined />
  </span>
);

const Caption = ({ value, isEdit, onStatusChange, onCaptionUpdate }) => {
  if (isEdit) {
    return <Input.TextArea autosize autoFocus onBlur={onCaptionUpdate} onPressEnter={onCaptionUpdate} defaultValue={value} />;
  }
  return (
    <div className="ant-card-meta-description" onClick={() => onStatusChange(true)}>
      {value || <em>Enter your caption here</em>}
    </div>
  );
};

const generateAction = (hideMeta, onMakePrimaryClick, onDelete, disabled, limitOnePhoto) => {
  if (!hideMeta || !disabled) {
    if (limitOnePhoto) {
      return [<DeleteAction onClick={onDelete} />];
    } else {
      return [<MakePrimaryAction onClick={onMakePrimaryClick} />, <DeleteAction onClick={onDelete} />];
    }
  }
};

class PhotoDisplayCard extends React.Component {
  constructor() {
    super();
    this.state = {
      isInEditMode: false
    };
  }

  handleOnEdit = status => {
    this.setState({
      isInEditMode: status
    });
  };

  handleOnCaptionUpdate = event => {
    const { onCaptionChange } = this.props;
    this.setState({
      isInEditMode: false
    });
    return onCaptionChange(event.target.value);
  };

  render() {
    const { isInEditMode } = this.state;
    const { imageLink, imageCaption, onMakePrimaryClick, onDelete, disabled, hideMeta, limitOnePhoto } = this.props;
    return (
      <Card
        className={styles.photoCard}
        hoverable
        cover={
          <div className={styles.photoCardCover}>
            <img alt="example" src={imageLink} className={styles.photoCardCoverImg} />
          </div>
        }
        actions={generateAction(hideMeta, onMakePrimaryClick, onDelete, disabled, limitOnePhoto)}
      >
        {!hideMeta && (
          <Card.Meta
            description={
              <Caption
                value={imageCaption}
                isEdit={!disabled && isInEditMode}
                onStatusChange={this.handleOnEdit}
                onCaptionUpdate={this.handleOnCaptionUpdate}
              />
            }
          />
        )}
      </Card>
    );
  }
}

PhotoDisplayCard.propTypes = {
  imageLink: PropTypes.string.isRequired,
  onCaptionChange: PropTypes.func.isRequired,
  onMakePrimaryClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

PhotoDisplayCard.defaultProps = {
  onCaptionChange: () => {},
  onMakePrimaryClick: () => {},
  onDelete: () => {},
  hideMeta: false
};

export default PhotoDisplayCard;
