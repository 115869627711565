import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Row, Col, Button } from 'antd';

import { useGetPaginatedMembers } from 'apis/member';

import Card from 'components/Card/Card';
import Title from 'components/Title/Title';
import SectionContainer from 'components/SectionContainer/SectionContainer';

import { useFetchConstant } from 'hooks/constants';

import { getLabelOfConstant } from 'utils/general';
import { getMemberDetailsRoute, getNewMemberRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';
import { withAppContext } from 'contexts/AppContext/AppContext';

const constructColumns = memberStatuses => [
  {
    ...constructColumn('Membership ID', 'membershipId', { hasSorter: true }),
    ...constructColumnFilterSearch('membershipId', 'Search Membership ID'),
    render: (text, record) => <Link to={getMemberDetailsRoute(record._id).path}>{text}</Link>
  },
  {
    ...constructColumn('Email Address', 'email'),
    ...constructColumnFilterSearch('email', 'Search Email Address')
  },
  {
    ...constructColumn('Name', 'name', { hasSorter: true }),
    ...constructColumnFilterSearch('name', 'Search Name')
  },
  {
    ...constructColumn('Contact Number', 'contact'),
    ...constructColumnFilterSearch('contact', 'Search Contact Number')
  },
  {
    ...constructColumn('Status', 'status'),
    ...constructColumnFilterRadio('status', memberStatuses),
    render: status => getLabelOfConstant(status, memberStatuses) || '-'
  }
];

const Members = ({ user }) => {
  const history = useHistory();
  const [query, setQuery] = useState({});
  const { isLoading: isMembersLoading, paginatedData: members, total } = useGetPaginatedMembers(query, user);
  const { selection: memberStatuses, isLoading: isMemberStatusesLoading } = useFetchConstant('memberStatuses');

  return (
    <Card>
      <SectionContainer>
        <Row justify="space-between" align="middle">
          <Col>
            <Title>Members</Title>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(getNewMemberRoute().path)}>
              Add new member
            </Button>
          </Col>
        </Row>
      </SectionContainer>
      <Table
        loading={isMembersLoading || isMemberStatusesLoading}
        rowKey={record => record._id}
        dataSource={members}
        columns={constructColumns(memberStatuses)}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default withAppContext(Members);
