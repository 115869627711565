import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Message } from 'antd';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { PictureOutlined } from '@ant-design/icons';

// import { REACT_APP_UPLOAD_ENDPOINT, REACT_APP_S3_IMAGES_BUCKET } from 'config/env';

import PhotoDisplayCard from './components/PhotoDisplayCard/PhotoDisplayCard';

import styles from './UploadPhoto.module.css';
import { uploadImage } from 'apis/s3Upload';

const { success, error, loading } = Message;

// const uploadOptions = {
//   server: REACT_APP_UPLOAD_ENDPOINT,
//   signingUrlQueryParams: { uploadType: 'avatar' }
// };
// const s3Url = `https://${REACT_APP_S3_IMAGES_BUCKET}.s3.amazonaws.com/`;

const UploadContainer = () => (
  <div className={styles.dragBoxContentContainer}>
    <div className={styles.dragBoxContentInnerContainer}>
      <p className={styles.dragBoxContentIcon}>
        <PictureOutlined />
      </p>
      <p className={styles.dragBoxContentTitle}>Click or drag image(s) to this area to upload</p>
    </div>
  </div>
);

const UploadPhoto = ({
  disabled,
  photos,
  onCaptionChange,
  onImageDelete,
  onImageMakePrimary,
  onUploadFinish,
  hideMeta,
  folderName,
  limitOnePhoto
}) => {
  const upload = async event => {
    const file = event[0];

    if (!file) return;

    const isPassValidation = imageValidation(file);

    if (!isPassValidation) return error({ content: 'Invalid image type! only accept PNG, JPG & JPEG' });

    const formData = new FormData();
    formData.append('image', file);

    loading('Uploading image');

    await uploadImage(formData, folderName)
      .then(response => {
        const { imgUrl } = response;
        onUploadFinish(imgUrl);
        success({ content: 'Success upload images' });
      })
      .catch(err => {
        console.log(err);
        error({ content: `Failed to upload ${file.name} ` });
      });
  };

  const imageValidation = file => {
    const allowedType = /image\/png|image\/jpeg|image\/jpg/;
    let isPassValidation = false;

    const checkImageType = allowedType.test(file.type);

    if (checkImageType) {
      isPassValidation = true;
    }
    return isPassValidation;
  };

  const onDropReject = () => error({ content: 'Maximum file upload size is 20MB' });

  return (
    <Row type="flex" gutter={{ xs: 0, md: 8, lg: 16 }}>
      {photos.map((photo, index) => (
        <Col key={index} span={24} md={12} lg={8} className={styles.photoContainer}>
          {console.log(photos)}
          <PhotoDisplayCard
            key={index}
            imageLink={photo.imageUrl}
            imageCaption={photo.caption}
            disabled={disabled}
            onCaptionChange={onCaptionChange(index)}
            onDelete={onImageDelete(index)}
            onMakePrimaryClick={onImageMakePrimary(index)}
            hideMeta={hideMeta}
            limitOnePhoto={limitOnePhoto}
          />
        </Col>
      ))}
      {!disabled && (!limitOnePhoto || (limitOnePhoto && photos.length <= 0)) && (
        <Col span={24} md={photos.length > 0 ? 12 : 24} lg={photos.length > 0 ? 8 : 24} className={styles.photoContainer}>
          <DropzoneS3Uploader
            onDrop={upload}
            maxSize={1024 * 1024 * 20}
            //upload={uploadOptions}
            onDropRejected={onDropReject}
            className={styles.dragBoxContainer}
          >
            <UploadContainer />
          </DropzoneS3Uploader>
        </Col>
      )}
    </Row>
  );
};

UploadPhoto.propTypes = {
  onCaptionChange: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  onImageMakePrimary: PropTypes.func.isRequired,
  onUploadFinish: PropTypes.func.isRequired,
  containerClassname: PropTypes.string,
  photos: PropTypes.array
};

UploadPhoto.defaultProps = {
  containerClassname: '',
  photos: [],
  onCaptionChange: () => {},
  onImageDelete: () => {},
  onImageMakePrimary: () => {},
  onUploadFinish: () => {}
};

export default UploadPhoto;
