import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';

import { AppContextProvider } from 'contexts/AppContext/AppContext';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import StandardLayout from 'layouts/StandardLayout/StandardLayout';

import App from 'pages/App/App';
import Forbidden403 from 'pages/403/Forbidden403';
import NotFound404 from 'pages/404/NotFound404';
import Hosts from 'pages/Hosts/Hosts';
import HostDetails from 'pages/HostDetails/HostDetails';
import Properties from 'pages/Properties/Properties';
import Login from 'pages/Login/Login';
import Bookings from 'pages/Bookings/Bookings';
import BookingDetails from 'pages/BookingDetails/BookingDetails';
import Overview from 'pages/Overview/Overview';
// import PackageOrders from 'pages/PackageOrders/PackageOrders';
// import PackageOrderDetails from 'pages/PackageOrderDetails/PackageOrderDetails';
import Users from 'pages/Users/Users';
import UserDetails from 'pages/UserDetails/UserDetails';
import Members from 'pages/Members/Members';
import MemberDetails from 'pages/MemberDetails/MemberDetails';
import Promotions from 'pages/Promotions/Promotions';
import PromotionDetails from 'pages/PromotionDetails/PromotionDetails';
import MyProfile from 'pages/MyProfile/MyProfile';
import ResetPassword from 'pages/ResetPassword/ResetPassword';

import {
  get403Route,
  get404Route,
  getHostsRoute,
  getNewHostRoute,
  getHostDetailRoute,
  getPropertiesRoute,
  getNewPropertyRoute,
  getPropertyDetailsRoute,
  getLoginRoute,
  getBookingsRoute,
  getNewBookingRoute,
  getBookingDetailRoute,
  getOverviewRoute,
  getPackageOrdersRoute,
  getNewPackageOrderRoute,
  getPackageOrderDetailRoute,
  getUsersRoute,
  getNewUserRoute,
  getUserDetailsRoute,
  getMembersRoute,
  getNewMemberRoute,
  getMemberDetailsRoute,
  getPromotionsRoute,
  getNewPromotionRoute,
  getPromotionDetailsRoute,
  getMyProfileRoute,
  getResetPasswordRoute,
  getQRCodeCheckInRoute,
  getNomadLocationRoute,
  getPartnersRoute,
  getNewPartnerRoute,
  getPartnerDetailsRoute,
  getVouchersRoute,
  getNewVoucherRoute,
  getVoucherDetailsRoute
} from 'utils/routes';

import './index.css';
import * as serviceWorker from './serviceWorker';
import PropertyDetails from 'pages/PropertyDetails/PropertyDetails';
import QRCodeCheckin from 'pages/QRCodeCheckin/QRCodeCheckin';
import NomadLocation from 'pages/NomadLocation/NomadLocation';
import Partners from 'pages/Partners/Partners';
import PartnerDetails from 'pages/PartnerDetails/PartnerDetails';
import Vouchers from 'pages/Vouchers/Vouchers';
import VoucherDetails from 'pages/VoucherDetails/VoucherDetails';

const queryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false
  }
};

const forbiddenRoute = get403Route();
const notFoundRoute = get404Route();
const hostsRoute = getHostsRoute();
const newHostRoute = getNewHostRoute();
const hostDetailRoute = getHostDetailRoute();
const propertiesRoute = getPropertiesRoute();
const newPropertyRoute = getNewPropertyRoute();
const propertyDetailsRoute = getPropertyDetailsRoute();
const loginRoute = getLoginRoute();
const bookingsRoute = getBookingsRoute();
const newBookingRoute = getNewBookingRoute();
const bookingDetailRoute = getBookingDetailRoute();
const overviewRoute = getOverviewRoute();
const packageOrdersRoute = getPackageOrdersRoute();
const newPackageOrderRoute = getNewPackageOrderRoute();
const packageOrderDetailRoute = getPackageOrderDetailRoute();
const usersRoute = getUsersRoute();
const newUserRoute = getNewUserRoute();
const userDetailsRoute = getUserDetailsRoute();
const membersRoute = getMembersRoute();
const newMemberRoute = getNewMemberRoute();
const memberDetailsRoute = getMemberDetailsRoute();
const promotionsRoute = getPromotionsRoute();
const newPromotionRoute = getNewPromotionRoute();
const promotionDetailsRoute = getPromotionDetailsRoute();
const myProfileRoute = getMyProfileRoute();
const resetPasswordRoute = getResetPasswordRoute();
const qrCodeCheckinRoute = getQRCodeCheckInRoute();
const nomadLocationRoute = getNomadLocationRoute();
const partnersRoute = getPartnersRoute();
const newPartnerRoute = getNewPartnerRoute();
const partnerDetailRoute = getPartnerDetailsRoute();
const vouchersRoute = getVouchersRoute();
const newVoucherRoute = getNewVoucherRoute();
const voucherDetailsRoute = getVoucherDetailsRoute();

const AppSwitch = () => {
  return (
    <StandardLayout>
      <Switch>
        <ProtectedRoute exact path={hostsRoute.path} accessRole={hostsRoute.role} component={Hosts} />
        <ProtectedRoute exact path={newHostRoute.path} accessRole={newHostRoute.role} component={HostDetails} />
        <ProtectedRoute exact path={hostDetailRoute.path} accessRole={hostDetailRoute.role} component={HostDetails} />

        <ProtectedRoute exact path={propertiesRoute.path} accessRole={propertiesRoute.role} component={Properties} />
        <ProtectedRoute exact path={newPropertyRoute.path} accessRole={newPropertyRoute.role} component={PropertyDetails} />
        <ProtectedRoute exact path={propertyDetailsRoute.path} accessRole={propertyDetailsRoute.role} component={PropertyDetails} />

        <ProtectedRoute exact path={bookingsRoute.path} accessRole={bookingsRoute.role} component={Bookings} />
        <ProtectedRoute exact path={newBookingRoute.path} accessRole={newBookingRoute.role} component={BookingDetails} />
        <ProtectedRoute exact path={bookingDetailRoute.path} accessRole={bookingDetailRoute.role} component={BookingDetails} />

        <ProtectedRoute exact path={overviewRoute.path} accessRole={overviewRoute.role} component={Overview} />

        {/* <ProtectedRoute exact path={packageOrdersRoute.path} accessRole={packageOrdersRoute.role} component={PackageOrders} />
        <ProtectedRoute exact path={newPackageOrderRoute.path} accessRole={newPackageOrderRoute.role} component={PackageOrderDetails} />
        <ProtectedRoute exact path={packageOrderDetailRoute.path} accessRole={packageOrderDetailRoute.role} component={PackageOrderDetails} /> */}

        <ProtectedRoute exact path={usersRoute.path} accessRole={usersRoute.role} component={Users} />
        <ProtectedRoute exact path={newUserRoute.path} accessRole={newUserRoute.role} component={UserDetails} />
        <ProtectedRoute exact path={userDetailsRoute.path} accessRole={userDetailsRoute.role} component={UserDetails} />

        <ProtectedRoute exact path={membersRoute.path} accessRole={membersRoute.role} component={Members} />
        <ProtectedRoute exact path={newMemberRoute.path} accessRole={newMemberRoute.role} component={MemberDetails} />
        <ProtectedRoute exact path={memberDetailsRoute.path} accessRole={memberDetailsRoute.role} component={MemberDetails} />

        <ProtectedRoute exact path={promotionsRoute.path} accessRole={promotionsRoute.role} component={Promotions} />
        <ProtectedRoute exact path={newPromotionRoute.path} accessRole={newPromotionRoute.role} component={PromotionDetails} />
        <ProtectedRoute exact path={promotionDetailsRoute.path} accessRole={promotionDetailsRoute.role} component={PromotionDetails} />

        <ProtectedRoute exact path={qrCodeCheckinRoute.path} accessRole={qrCodeCheckinRoute.role} component={QRCodeCheckin} />

        <ProtectedRoute exact path={nomadLocationRoute.path} accessRole={nomadLocationRoute.role} component={NomadLocation} />

        <ProtectedRoute exact path={partnersRoute.path} accessRole={partnersRoute.role} component={Partners} />
        <ProtectedRoute exact path={newPartnerRoute.path} accessRole={newPartnerRoute.role} component={PartnerDetails} />
        <ProtectedRoute exact path={partnerDetailRoute.path} accessRole={partnerDetailRoute.role} component={PartnerDetails} />

        <ProtectedRoute exact path={vouchersRoute.path} accessRole={vouchersRoute.role} component={Vouchers} />
        <ProtectedRoute exact path={newVoucherRoute.path} accessRole={newVoucherRoute.role} component={VoucherDetails} />
        <ProtectedRoute exact path={voucherDetailsRoute.path} accessRole={voucherDetailsRoute.role} component={VoucherDetails} />

        <ProtectedRoute exact path={myProfileRoute.path} accessRole={myProfileRoute.role} component={MyProfile} />
        <ProtectedRoute exact path={resetPasswordRoute.path} accessRole={resetPasswordRoute.role} component={ResetPassword} />

        <Redirect exact from="*" to={notFoundRoute.path} />
      </Switch>
    </StandardLayout>
  );
};

ReactDOM.render(
  <ReactQueryConfigProvider config={queryConfig}>
    <Helmet>
      <title>HostAStay Admin Panel</title>
    </Helmet>
    <Router>
      <AppContextProvider>
        <Switch>
          <Route exact path={'/'} component={App} />
          <Route exact path={forbiddenRoute.path} component={Forbidden403} />
          <Route exact path={loginRoute.path} component={Login} />
          <Route exact path={notFoundRoute.path} component={NotFound404} />
          <AppSwitch />
        </Switch>
      </AppContextProvider>
    </Router>
  </ReactQueryConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
