import { Card, Col, Row } from 'antd';
import FormInput from 'components/FormInput/FormInput';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import React from 'react';
import { FORM_EXT_BOOKING_URL, FORM_IS_OWNED_BY_HAS } from '../../utils/formKey';
import { constructGeneralToggleSelection } from 'utils/constants';

const AdditionalDetailsCard = () => {
  return (
    <Col>
      <Card title="Additional Details">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput name={FORM_EXT_BOOKING_URL} label="External booking url" placeholder="Type in external booking url if exists" type="url" />
          </Col>
          <Col span={24} md={12}>
            <FormRadioButton
              name={FORM_IS_OWNED_BY_HAS}
              label="Is owned by HAS"
              selections={constructGeneralToggleSelection()}
              buttonStyle="solid"
              defaultValue={false}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default AdditionalDetailsCard;
