import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, message, Row, Skeleton, Table, Tabs } from 'antd';
import moment from 'moment';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useGetMemberById, postCreateMember, patchUpdateMember, patchUpdateMemberStatus } from 'apis/member';

import { withAppContext } from 'contexts/AppContext/AppContext';

import ActivationButton from 'components/ActivationButton/ActivationButton';
import ClickableTextButton from 'components/ClickableTextButton/ClickableTextButton';
import FormInput from 'components/FormInput/FormInput';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import FormSelection from 'components/FormSelection/FormSelection';
import SectionCard from 'components/SectionCard/SectionCard';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Title from 'components/Title/Title';

import { useFetchConstant } from 'hooks/constants';

import { guard, getLabelOfConstant } from 'utils/general';
import { getBookingDetailRoute, getPackageOrderDetailRoute, getMembersRoute, getMemberDetailsRoute } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio } from 'utils/table/table';

import { StatusTag, PasswordContainer, PasswordTitle, PasswordDesc, PasswordRequirement } from './MemberDetails.styles';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import { constructGeneralToggleSelection, ROLE_MDEC_ADMIN } from '../../utils/constants';

const { confirm, error } = Modal;
const { useForm } = Form;
const { TabPane } = Tabs;

const MemberStatusTag = ({ status, label, memberStatusesConst }) => {
  const getTagColor = () => {
    switch (status) {
      case memberStatusesConst.ACTIVE.code:
        return 'success';
      case memberStatusesConst.INACTIVE.code:
        return 'error';
      default:
        return 'default';
    }
  };
  return <StatusTag color={getTagColor()}>{label}</StatusTag>;
};

const constructPackagesColumns = packages => [
  {
    ...constructColumn('Package Code', 'packageCode', { width: '20%' }),
    ...constructColumnFilterSearch('packageCode', 'Search Package Code'),
    render: (text, record) => {
      return (
        <Link to={getPackageOrderDetailRoute(record._id).path} target="_blank">
          {text}
        </Link>
      );
    }
  },
  {
    ...constructColumn('Date Purchased', 'datePurchased', { isDate: true, hasSorter: true, width: 150 })
  },
  {
    ...constructColumn('Package', 'packagePurchased', { hasSorter: true }),
    ...constructColumnFilterSearch('packagePurchased', 'Search Package Purchased'),
    render: text => getLabelOfConstant(text, packages)
  },
  {
    ...constructColumn('Night(s) Credit', 'nightsCredit', { width: '15%', hasSorter: true })
  },
  {
    ...constructColumn('Night(s) Left', 'nightsLeft', { width: '15%', hasSorter: true })
  }
];

const constructBookingsColumns = (bookingStatuses, bookingStatusesConst) => [
  {
    ...constructColumn('Confirmation Code', 'code', { width: 180 }),
    ...constructColumnFilterSearch('code', 'Search Confirmation Code'),
    render: (text, record) => {
      return (
        <Link to={getBookingDetailRoute(record._id).path} target="_blank">
          {text}
        </Link>
      );
    }
  },
  {
    ...constructColumn('Check In Date', 'startDate', { isDate: true, hasSorter: true, width: 150 })
  },
  {
    ...constructColumn('Check Out Date', 'endDate', { isDate: true, hasSorter: true, width: 150 })
  },
  {
    ...constructColumn('Property Name', 'propertyName'),
    ...constructColumnFilterSearch('propertyName', 'Search Property')
  },
  {
    ...constructColumn('Guest Name', 'guestName'),
    ...constructColumnFilterSearch('guestName', 'Search Guest Name')
  },
  {
    ...constructColumn('Host Name', 'hostName'),
    ...constructColumnFilterSearch('hostName', 'Search Host')
  },
  {
    ...constructColumn('Status', 'status'),
    ...constructColumnFilterRadio('status', bookingStatuses),
    render: (status, record) => {
      const isPastBooking = moment(record.startDate).isBefore(moment(), 'day');
      const isPending = bookingStatusesConst && [bookingStatusesConst.PENDING.code, bookingStatusesConst.STRIPE_UNPAID.code].includes(status);

      return isPastBooking && isPending ? 'Expired' : getLabelOfConstant(status, bookingStatuses);
    }
  }
];

const useFetchConstants = () => {
  const { selection: memberStatuses, data: memberStatusesConst, isLoading: isMemberStatusesLoading } = useFetchConstant('memberStatuses');
  const { selection: bookingStatuses, data: bookingStatusesConst, isLoading: isBookingStatusesLoading } = useFetchConstant('bookingStatuses');
  const { selection: identificationTypes, data: identificationTypesConst, isLoading: isIndetificationTypesLoading } = useFetchConstant(
    'identificafionTypes'
  );
  const { selection: countries, data: countriesConst, isLoading: isCountriesLoading } = useFetchConstant('countries', {
    valueKey: 'iso3',
    labelKey: 'name',
    query: { isFetchingAllCountries: true }
  });
  const { selection: countryCodes, data: countryCodesConst, isLoading: isCountryCodesLoading } = useFetchConstant('countries', {
    valueKey: 'phoneCode',
    labelKey: 'phoneCode',
    query: { isFetchingAllCountries: true }
  });
  const { isLoading: isPackagesLoading, selection: packages } = useFetchConstant('packages');

  const isLoading =
    isMemberStatusesLoading ||
    isPackagesLoading ||
    isBookingStatusesLoading ||
    isCountriesLoading ||
    isIndetificationTypesLoading ||
    isCountryCodesLoading;
  const selections = { memberStatuses, bookingStatuses, identificationTypes, countries, countryCodes };
  const datas = { memberStatusesConst, bookingStatusesConst, identificationTypesConst, packages, countriesConst, countryCodesConst };

  return { isLoading, selections, datas };
};

const Member = ({ user }) => {
  const history = useHistory();
  const [form] = useForm();
  const { id: memberId } = useParams();
  const [selectedIdentificationType, setSelectedIdentificationType] = useState('');
  const {
    isLoading: isMemberLoading,
    data: { bookings, member, packageOrders }
  } = useGetMemberById(memberId);

  const {
    isLoading: isConstantsLoading,
    selections: { memberStatuses, bookingStatuses, identificationTypes, countries, countryCodes },
    datas: { memberStatusesConst, bookingStatusesConst, identificationTypesConst, packages, countriesConst, countryCodesConst }
  } = useFetchConstants();
  const isEditMode = useMemo(() => !!memberId, [memberId]);
  const passwordTitle = useMemo(() => (isEditMode ? 'Reset Password' : 'Password'), [isEditMode]);
  const isLoading = useMemo(() => isConstantsLoading || isMemberLoading, [isConstantsLoading, isMemberLoading]);

  const getIDLabel = useCallback(() => {
    const selectedIdType = identificationTypes.find(type => type.value === selectedIdentificationType);
    const selectedIdTypeLabel = (selectedIdType && selectedIdType.label) || identificationTypesConst.NRIC.label;

    return selectedIdTypeLabel + ' Number';
  }, [selectedIdentificationType, identificationTypes, identificationTypesConst]);

  const currentMemberStatus = guard(() => member && member.status, undefined);
  const isActivated = !isLoading && currentMemberStatus === memberStatusesConst.ACTIVE.code;

  useEffect(() => {
    if (!isEditMode) {
      if (identificationTypesConst) {
        form.setFieldsValue({ identification: { type: identificationTypesConst.NRIC.code } });
        setSelectedIdentificationType(identificationTypesConst.NRIC.code);
      }
      if (countriesConst) {
        form.setFieldsValue({ identification: { nationality: countriesConst.MALAYSIA.iso3 } });
      }
      if (countryCodesConst) {
        form.setFieldsValue({ contact: { countryCode: countryCodesConst.MALAYSIA.phoneCode } });
      }
      if (bookingStatusesConst) {
        form.setFieldsValue({ status: bookingStatusesConst.CONFIRM.code });
      }
    }
  }, [identificationTypesConst, countriesConst, countryCodesConst, bookingStatusesConst, form, isEditMode]);

  const handleOnActivate = () =>
    patchUpdateMemberStatus(memberId, memberStatusesConst.ACTIVE.code).then(() => {
      message.success(`This member has been successfully activated.`);
    });

  const handleOnDeactivate = () =>
    patchUpdateMemberStatus(memberId, memberStatusesConst.INACTIVE.code).then(() => {
      message.success(`This member has been successfully deactivated.`);
    });

  const handleOnSave = values => {
    const payload = {
      ...values
    };
    if (isEditMode) {
      confirm({
        title: 'Are you sure you want to overwrite existing data?',
        content: 'You will not be able to undo this action, but you may update it again.',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          patchUpdateMember(memberId, payload)
            .then(() => {
              message.success('Update success!');
            })
            .catch(ex => {
              error({
                title: ex.message
              });
            });
        },
        onCancel() {}
      });
    } else {
      postCreateMember(payload)
        .then(newMember => {
          Modal.success({
            title: 'Member has been created successfully!',
            okText: 'View created member',
            onOk() {
              history.push(getMemberDetailsRoute(newMember._id).path);
            }
          });
        })
        .catch(ex => {
          error({
            title: ex.message
          });
        });
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div style={{ width: '100%' }}>
          <ClickableTextButton text="Back to Members" url={getMembersRoute().path} />
          <Row type="flex" align="middle" style={{ marginBottom: '16px' }}>
            <Title>{isEditMode ? `${member.membershipId} - ${member.name}` : 'New Member'}</Title>
            {isEditMode && (
              <MemberStatusTag
                status={currentMemberStatus}
                label={getLabelOfConstant(currentMemberStatus, memberStatuses)}
                memberStatusesConst={memberStatusesConst}
              />
            )}
          </Row>
          <div>
            <Tabs
              defaultActiveKey="generalDetails"
              tabBarExtraContent={
                <ActivationButton
                  id={memberId}
                  status={currentMemberStatus}
                  isActivated={isActivated}
                  label="member"
                  onActivate={handleOnActivate}
                  onDeactivate={handleOnDeactivate}
                />
              }
            >
              <TabPane tab="General Details" key="generalDetails">
                <Form
                  form={form}
                  initialValues={member}
                  scrollToFirstError={true}
                  style={{ width: '100%' }}
                  onFinish={handleOnSave}
                  onFinishFailed={handleOnSaveFailed}
                >
                  <SectionContainer>
                    <SectionCard title="Account Details">
                      <Row gutter={16}>
                        <Col span={12}>
                          <FormInput label="Name" name="name" requiredErrorMessage="Please enter member's name." />
                          <FormInput
                            label="Email Address"
                            name="email"
                            type="email"
                            placeholder="example@mail.com"
                            requiredErrorMessage="Please enter email address."
                            disabled={isEditMode}
                          />
                          <FormGroupSelectionInput
                            label="Contact Number"
                            inputName={['contact', 'contactNumber']}
                            inputType="number"
                            selectionName={['contact', 'countryCode']}
                            selections={countryCodes}
                            requiredErrorMessage="Please enter contact number"
                            disabled={isEditMode}
                          />
                          <FormRadioButton
                            name="isMdecMember"
                            label="Is MDEC Member"
                            selections={constructGeneralToggleSelection()}
                            buttonStyle="solid"
                            defaultValue={user.role === ROLE_MDEC_ADMIN ? true : false}
                          />
                        </Col>
                        <Col span={12}>
                          <PasswordContainer>
                            <PasswordTitle>{passwordTitle}</PasswordTitle>
                            <PasswordDesc>
                              This password will be used for logging in. Members can self-reset their password in their user profile page.
                            </PasswordDesc>
                            <PasswordRequirement>Use alphanumeric with a length of 8 - 20 characters</PasswordRequirement>
                            <FormPasswordInput
                              name="password"
                              placeholder="P@ssW0rd"
                              requiredErrorMessage={!isEditMode && "Please enter member's password."}
                              checkPattern={true}
                            />
                          </PasswordContainer>
                        </Col>
                      </Row>
                    </SectionCard>
                  </SectionContainer>
                  <SectionContainer>
                    <SectionCard title="Identification Details">
                      <Row gutter={16}>
                        <Col span={24}>
                          <FormSelection
                            label="Nationality"
                            name={['identification', 'nationality']}
                            placeholder="Select Nationality"
                            selections={countries}
                          />
                        </Col>
                        <Col span={24} md={12}>
                          <FormSelection
                            label="ID Type"
                            name={['identification', 'type']}
                            placeholder="Select ID Type"
                            selections={identificationTypes}
                            onChange={setSelectedIdentificationType}
                          />
                        </Col>
                        <Col span={24} md={12}>
                          <FormInput
                            label={getIDLabel()}
                            name={['identification', 'id']}
                            type={selectedIdentificationType === identificationTypesConst.NRIC.code ? 'nric' : 'default'}
                          />
                        </Col>
                      </Row>
                    </SectionCard>
                  </SectionContainer>
                  <Row gutter={8} style={{ marginBottom: '32px' }}>
                    <Col>
                      <Button htmlType="submit" type="primary" icon={<CheckOutlined />}>
                        {isEditMode ? 'Save' : 'Create'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </TabPane>
              {isEditMode && (
                <>
                  <TabPane tab="Stays Booked" key="staysBooked">
                    <Table
                      loading={isLoading}
                      rowKey={record => record._id}
                      dataSource={bookings}
                      columns={constructBookingsColumns(bookingStatuses, bookingStatusesConst)}
                      scroll={{ x: 800 }}
                    />
                  </TabPane>
                  <TabPane tab="Package Orders" key="packageOrders">
                    <Table
                      loading={isLoading}
                      rowKey={record => record._id}
                      dataSource={packageOrders}
                      columns={constructPackagesColumns(packages)}
                      scroll={{ x: 800 }}
                    />
                  </TabPane>
                </>
              )}
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};

export default withAppContext(Member);
