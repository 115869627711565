import React, { useMemo, useCallback } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import { CONTACT_NUMBER_REGEX, EMAIL_ADDRESS_REGEX, NRIC_REGEX, PRICE_REGEX, USERNAME_REGEX, URL_REGEX } from 'utils/constants';
import { guard } from 'utils/general';

const { TextArea, Password } = Input;

const INPUT_TYPE_CONTACT_NUMBER = 'contactNumber';
const INPUT_TYPE_DEFAULT = 'default';
const INPUT_TYPE_EMAIL = 'email';
const INPUT_TYPE_FINANCIAL = 'financial';
const INPUT_TYPE_NRIC = 'nric';
const INPUT_TYPE_TEXTAREA = 'textArea';
const INPUT_TYPE_PASSWORD = 'password';
const INPUT_TYPE_USERNAME = 'username';
const INPUT_TYPE_URL = 'url';

const FormInput = ({ disabled, extraProps, label, name, placeholder, requiredErrorMessage, suffix, type, extraRules, isUpperCase = false }) => {
  const normalize = isUpperCase ? value => guard(() => value.toUpperCase(), value) : undefined;

  const localExtraProps = useMemo(
    () => ({
      ...extraProps,
      type: type === INPUT_TYPE_FINANCIAL ? 'number' : extraProps.type
    }),
    [extraProps, type]
  );

  const extraPropsForRules = useMemo(() => {
    const rules = [];

    if (type === INPUT_TYPE_FINANCIAL) {
      rules.push({
        pattern: PRICE_REGEX,
        message: `${label} must be numeric with two decimals only.`
      });
    }
    if (type === INPUT_TYPE_CONTACT_NUMBER) {
      rules.push({
        pattern: CONTACT_NUMBER_REGEX,
        message: `${label} must meet the requirements.`
      });
    }
    if (type === INPUT_TYPE_EMAIL) {
      rules.push({
        pattern: EMAIL_ADDRESS_REGEX,
        message: 'Please enter a valid email address in lowercase. Eg: example@mail.com'
      });
    }
    if (type === INPUT_TYPE_USERNAME) {
      rules.push({
        pattern: USERNAME_REGEX,
        message: `Username must meet the requirements.`
      });
    }
    if (type === INPUT_TYPE_NRIC) {
      rules.push({
        pattern: NRIC_REGEX,
        message: `${label} must only contain 12 numbers without hyphen '-'.`
      });
    }
    if (type === INPUT_TYPE_URL) {
      rules.push({
        pattern: URL_REGEX,
        message: `${label} must be a valid url.`
      });
    }

    if (extraRules) {
      rules.push(...extraRules);
    }

    return rules;
  }, [extraRules, label, type]);

  const getInputComponent = useCallback(() => {
    if (type === INPUT_TYPE_TEXTAREA) {
      return <TextArea rows={4} disabled={disabled} placeholder={placeholder} {...localExtraProps} />;
    } else if (type === INPUT_TYPE_PASSWORD) {
      return <Password disabled={disabled} placeholder={placeholder} suffix={suffix} type={type} {...localExtraProps} />;
    } else {
      return <Input disabled={disabled} placeholder={placeholder} suffix={suffix} type={type} {...localExtraProps} />;
    }
  }, [disabled, localExtraProps, placeholder, suffix, type]);

  return (
    <>
      <FormItem extraRules={extraPropsForRules} name={name} label={label} requiredErrorMessage={requiredErrorMessage} normalize={normalize}>
        {getInputComponent()}
      </FormItem>
    </>
  );
};

FormInput.propTypes = {
  extraProps: PropTypes.object,
  type: PropTypes.oneOf([INPUT_TYPE_DEFAULT, INPUT_TYPE_FINANCIAL, INPUT_TYPE_CONTACT_NUMBER, INPUT_TYPE_EMAIL, INPUT_TYPE_TEXTAREA, INPUT_TYPE_NRIC])
};

FormInput.defaultProps = {
  extraProps: {},
  type: INPUT_TYPE_DEFAULT
};

export default FormInput;
