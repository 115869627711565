import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { Button, Form, message, Modal, Row, Skeleton } from 'antd';
import { deletePartner, patchUpdatePartner, postCreatePartner } from 'apis/partner';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getPartnerDetailsRoute, getPartnersRoute } from 'utils/routes';
import PartnerDetailsCard from './components/PartnerDetailsCard';

const { useForm } = Form;
const { confirm, error } = Modal;

const PartnerForm = ({ isEditMode, partnerDetails }) => {
  const [form] = useForm();
  const history = useHistory();
  const isLoading = false;
  const formDetails = partnerDetails
    ? {
        name: partnerDetails.name,
        websiteUrl: partnerDetails.websiteUrl,
        description: partnerDetails.description
      }
    : {};
  if (partnerDetails.logoUrl) {
    formDetails.logoUrl = [{ imageUrl: partnerDetails.logoUrl }];
  }
  const partnerId = partnerDetails ? partnerDetails._id : null;

  const handleOnSubmitForm = values => {
    const payload = {
      name: values.name,
      websiteUrl: values.websiteUrl,
      description: values.description
    };

    if (values.logoUrl && values.logoUrl.length > 0) {
      payload.logoUrl = values.logoUrl[0].imageUrl;
    }

    if (isEditMode) {
      confirm({
        title: 'Are you sure you want to overwrite existing data?',
        content: 'You will not be able to undo this action, but you may update it again.',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          if (partnerId) {
            patchUpdatePartner(partnerId, payload)
              .then(() => {
                message.success('Update success!');
              })
              .catch(ex => {
                error({
                  title: ex.message
                });
              });
          } else {
            error({
              title: 'No Partner ID'
            });
          }
        },
        onCancel() {}
      });
    } else {
      postCreatePartner(payload)
        .then(newPartner => {
          Modal.success({
            title: 'New Partner Successfully Created!',
            okText: 'View created partner',
            onOk() {
              history.push(getPartnerDetailsRoute(newPartner._id).path);
            }
          });
        })
        .catch(ex => {
          error({
            title: ex.message
          });
        });
    }
  };

  const handleOnDeleteButtonClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this partner?',
      content: 'This partner will be permanently deleted.',
      icon: <WarningTwoTone twoToneColor="red" />,
      okText: 'Confirm',
      onOk() {
        deletePartner(partnerId).then(() => {
          message.success('You have successfully deleted this partner!');
          history.push(getPartnersRoute().path);
        });
      },
      onCancel() {}
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[0, 16]}>
          <Form
            form={form}
            style={{ width: '100%' }}
            initialValues={formDetails}
            onFinish={handleOnSubmitForm}
            // onFinishFailed={handleOnSubmitFailed}
          >
            <PartnerDetailsCard isEditMode={isEditMode} form={form} />
            <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />} style={{ marginRight: 8 }}>
              {isEditMode ? 'Save' : 'Create'}
            </Button>
            {isEditMode && (
              <Button type="danger" onClick={handleOnDeleteButtonClick} size="large" icon={<DeleteOutlined />}>
                Delete
              </Button>
            )}
          </Form>
        </Row>
      )}
    </>
  );
};

export default PartnerForm;
