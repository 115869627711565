import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import PromotionSettingFormItems from 'components/PromotionSettingFormItems/PromotionSettingFormItems';

import { ALPHANUMERIC_REGEX } from 'utils/constants';
import FormDatePicker from 'components/FormDatePicker/FormDatePicker';

/* =============================================== Main Component ===============================================*/

const PromotionDetailsForm = ({ form, promotionTypes, propertySelections, isEdit, isSubmitting, initialValues, onFormSave, onDeletePromotion }) => {
  const { type: initialPromotionType } = initialValues;

  return (
    <Form form={form} initialValues={initialValues} style={{ width: '100%' }}>
      <Card title="Promotion details">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput name="name" label="Name" placeholder="Please input Promotion Name" requiredErrorMessage="Please input Promotion Name" />
          </Col>
          <Col span={24} md={12}>
            <FormInput
              name="code"
              label="Code"
              placeholder="The promo code for guest to apply"
              requiredErrorMessage="Please input Code"
              extraRules={[{ pattern: ALPHANUMERIC_REGEX, message: 'Code must only consists of numbers, letters and no white space.' }]}
              disabled={isEdit}
              isUpperCase
            />
          </Col>
          <Col span={24}>
            <PromotionSettingFormItems
              promotionTypeName="type"
              promotionAmountName="amount"
              promotionTypeConstants={promotionTypes}
              initialSelectedType={initialPromotionType}
            />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              name="properties"
              label="Enrolled properties (*if leave empty, promotion applied to all properties)"
              size="large"
              isMultiple
              selections={propertySelections}
              placeholder="Please select enroll properties, leave empty if enroll all properties"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput name="redemptionCount" label="Total Redeemed" disabled={true} />
          </Col>
          <Col span={24} md={12}>
            <FormDatePicker
              label="Start Date"
              name="startDate"
              extraProps={{
                allowClear: false
              }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormDatePicker
              label="End Date"
              name="endDate"
              extraProps={{
                allowClear: false
              }}
            />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col>
            <Button type="primary" icon={<CheckOutlined />} loading={isSubmitting} onClick={onFormSave}>
              {isEdit ? 'Save' : 'Create'}
            </Button>
          </Col>
          {isEdit && (
            <Col>
              <Button type="danger" icon={<DeleteOutlined />} loading={isSubmitting} onClick={onDeletePromotion}>
                Delete
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    </Form>
  );
};

PromotionDetailsForm.propTypes = {
  form: PropTypes.object.isRequired,
  promotionTypes: PropTypes.array.isRequired,
  propertySelections: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  onFormSave: PropTypes.func,
  onDeletePromotion: PropTypes.func
};

PromotionDetailsForm.defaultProps = {
  promotionTypes: [],
  propertySelections: [],
  isEdit: false,
  isSubmitting: false,
  initialValues: {},
  onFormSave: undefined,
  onDeletePromotion: undefined
};

export default PromotionDetailsForm;
