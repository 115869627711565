import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';

import FormItem from 'components/FormItem/FormItem';
import { BedRoomListItem, BedConfiguration } from 'components/Bedrooms/Bedrooms';

import { RoomListContainer, Description, BedsConfigurationContainer } from './FormBedrooms.styles';

const validateBedrooms = async (rule, bedrooms) => {
  if (!bedrooms || bedrooms.length === 0) {
    throw new Error('At least 1 room is required to create a new room type');
  }

  for (let i = 0; i < bedrooms.length; i++) {
    if (bedrooms[i].totalBedCount === 0) {
      throw new Error('Room must have at least 1 bed');
    }
  }
};

const Bedrooms = ({ canEdit, value: bedrooms, fieldName, form, bedTypes }) => {
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(-1);

  const handleOnBedroomEdit = index => e => {
    setSelectedRoomIndex(index);
  };

  const generateRoomName = index => `Bedroom ${index + 1}`;

  const mapBedTypesToConfiguration = (count, bedTypes) => {
    const newBedTypes = Object.keys(bedTypes).reduce((acc, key) => {
      return {
        ...acc,
        [key]: { ...bedTypes[key], count: 0 }
      };
    }, {});
    return {
      beds: { ...newBedTypes },
      name: generateRoomName(count),
      totalBedCount: 0
    };
  };

  const handleOnBedroomRemove = indexToRemove => e => {
    const newBedrooms = bedrooms
      .filter((config, index) => index !== indexToRemove)
      .map((bedroom, index) => {
        return {
          ...bedroom,
          name: generateRoomName(index)
        };
      });

    form.setFieldsValue({
      [fieldName]: [...newBedrooms]
    });

    setSelectedRoomIndex(calculateSelectedRoomIndexOnRemove(indexToRemove, selectedRoomIndex));
  };

  const calculateSelectedRoomIndexOnRemove = (indexToRemove, selectedRoomIndex) => {
    if (indexToRemove === selectedRoomIndex) {
      return -1;
    } else if (selectedRoomIndex > indexToRemove) {
      return selectedRoomIndex - 1;
    }
    return selectedRoomIndex;
  };

  const handleOnAddBedroom = () => {
    const bedroomsCount = (bedrooms && bedrooms.length) || 0;
    const bedroomConfiguration = mapBedTypesToConfiguration(bedroomsCount, bedTypes);

    form.setFieldsValue({
      [fieldName]: bedrooms !== undefined ? [...bedrooms, bedroomConfiguration] : [bedroomConfiguration]
    });
    setSelectedRoomIndex(bedroomsCount);
  };

  const generateBeds = bedrooms => {
    const selectedBedRooms = bedrooms[selectedRoomIndex].beds;
    return Object.keys(selectedBedRooms).map(key => {
      return {
        key,
        label: selectedBedRooms[key].label,
        count: selectedBedRooms[key].count
      };
    });
  };

  const handleOnBedUpdate = (isIncrement = true) => key => e => {
    const newBedrooms = bedrooms;
    const selectedBedRoom = newBedrooms[selectedRoomIndex];
    const selectedBedTypeCount = selectedBedRoom.beds[key].count;

    let newBedroom;
    if (isIncrement) {
      newBedroom = {
        ...selectedBedRoom,
        beds: {
          ...selectedBedRoom.beds,
          [key]: {
            ...selectedBedRoom.beds[key],
            count: selectedBedTypeCount + 1
          }
        },
        totalBedCount: selectedBedRoom.totalBedCount + 1
      };
    } else {
      if (selectedBedTypeCount && selectedBedTypeCount > 0) {
        newBedroom = {
          ...selectedBedRoom,
          beds: {
            ...selectedBedRoom.beds,
            [key]: {
              ...selectedBedRoom.beds[key],
              count: selectedBedTypeCount - 1
            }
          },
          totalBedCount: selectedBedRoom.totalBedCount - 1
        };
      }
    }

    if (newBedroom) {
      newBedrooms[selectedRoomIndex] = newBedroom;
      form.setFieldsValue({
        [fieldName]: [...newBedrooms]
      });
    }
  };

  return (
    <Row>
      <Col span={24} md={12}>
        <RoomListContainer>
          {bedrooms &&
            bedrooms.length > 0 &&
            bedrooms.map((bedConfiguration, index) => {
              const { beds, name } = bedConfiguration;

              const description = Object.keys(beds).reduce((newDescription, bedKey) => {
                const bed = beds[bedKey];
                if (bed.count && bed.count > 0) {
                  return `${newDescription} ${bed.count} ${bed.label}`;
                }
                return newDescription;
              }, '') || <Description> No bed added (At least 1 is required)</Description>;
              return (
                <BedRoomListItem
                  key={name}
                  disabled={!canEdit}
                  title={name}
                  description={description}
                  onEditClick={handleOnBedroomEdit(index)}
                  onRemoveClick={handleOnBedroomRemove(index)}
                  shouldDisableRemove={bedrooms.length === 1}
                />
              );
            })}
        </RoomListContainer>
        {canEdit && (
          <div style={{ width: '100%' }}>
            <Button block type="primary" onClick={handleOnAddBedroom}>
              Add a new room
            </Button>
          </div>
        )}
      </Col>
      {bedrooms && bedrooms.length > 0 && selectedRoomIndex > -1 && (
        <Col span={24} md={12}>
          <BedsConfigurationContainer>
            <BedConfiguration
              roomName={bedrooms[selectedRoomIndex].name}
              beds={generateBeds(bedrooms)}
              onBedIncrement={handleOnBedUpdate(true)}
              onBedDecrement={handleOnBedUpdate(false)}
            />
          </BedsConfigurationContainer>
        </Col>
      )}
    </Row>
  );
};

const FormBedrooms = ({ canEdit, name, form, bedTypes }) => {
  return (
    <FormItem name={name} noStyle extraRules={[{ required: true, validator: validateBedrooms }]}>
      <Bedrooms canEdit={canEdit} fieldName={name} form={form} bedTypes={bedTypes} />
    </FormItem>
  );
};

export default FormBedrooms;
