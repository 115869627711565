import React from 'react';
import { Button, Form, message, Modal, Row, Skeleton } from 'antd';
import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import VoucherDetailsForm from './VoucherDetailsForm';
import { DATE_FORMAT } from 'utils/constants';
import moment from 'moment';
import { getVoucherDetailsRoute, getVouchersRoute } from 'utils/routes';
import { deleteVoucher, patchUpdateVoucher, postCreateVoucher } from 'apis/voucher';
import { useHistory } from 'react-router-dom';

const { useForm } = Form;
const { confirm, error } = Modal;

const VoucherDetailsCard = ({ isEditMode, voucherDetails, partners }) => {
  const [form] = useForm();
  const history = useHistory();
  const isLoading = false;

  const formDetails = voucherDetails
    ? {
        name: voucherDetails.name,
        partner: voucherDetails.partnerId,
        desc: voucherDetails.desc,
        voucherUrl: voucherDetails.voucherUrl
      }
    : {};

  if (voucherDetails.startDate && voucherDetails.endDate) {
    formDetails.startDate = moment(voucherDetails.startDate, DATE_FORMAT);
    formDetails.endDate = moment(voucherDetails.endDate, DATE_FORMAT);
  }

  const voucherId = voucherDetails ? voucherDetails._id : null;

  const handleOnSubmitForm = values => {
    const payload = {
      partnerId: values.partner,
      name: values.name,
      voucherUrl: values.voucherUrl,
      desc: values.desc,
      startDate: values.startDate ? values.startDate.format(DATE_FORMAT) : null,
      endDate: values.endDate ? values.endDate.format(DATE_FORMAT) : null
    };

    if (isEditMode) {
      confirm({
        title: 'Are you sure you want to overwrite existing data?',
        content: 'You will not be able to undo this action, but you may update it again.',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          if (voucherId) {
            patchUpdateVoucher(voucherId, payload)
              .then(() => {
                message.success('Update success!');
              })
              .catch(ex => {
                error({
                  title: ex.message
                });
              });
          } else {
            error({
              title: 'No Voucher ID'
            });
          }
        },
        onCancel() {}
      });
    } else {
      postCreateVoucher(payload)
        .then(newVoucher => {
          Modal.success({
            title: 'New Voucher Successfully Created!',
            okText: 'View created voucher',
            onOk() {
              history.push(getVoucherDetailsRoute(newVoucher._id).path);
            }
          });
        })
        .catch(ex => {
          error({
            title: ex.message
          });
        });
    }
  };

  const handleOnDeleteButtonClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this voucher?',
      content: 'This voucher will be permanently deleted.',
      icon: <WarningTwoTone twoToneColor="red" />,
      okText: 'Confirm',
      onOk() {
        deleteVoucher(voucherId).then(() => {
          message.success('You have successfully deleted this voucher!');
          history.push(getVouchersRoute().path);
        });
      },
      onCancel() {}
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[0, 16]}>
          <Form
            form={form}
            style={{ width: '100%' }}
            initialValues={formDetails}
            onFinish={handleOnSubmitForm}
            // onFinishFailed={handleOnSubmitFailed}
          >
            <VoucherDetailsForm isEditMode={isEditMode} form={form} partners={partners} />
            <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />} style={{ marginRight: 8 }}>
              {isEditMode ? 'Save' : 'Create'}
            </Button>
            {isEditMode && (
              <Button type="danger" onClick={handleOnDeleteButtonClick} size="large" icon={<DeleteOutlined />}>
                Delete
              </Button>
            )}
          </Form>
        </Row>
      )}
    </>
  );
};

export default VoucherDetailsCard;
