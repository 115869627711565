import { Col, Row } from 'antd';
import FormDatePicker from 'components/FormDatePicker/FormDatePicker';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import SectionCard from 'components/SectionCard/SectionCard';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import React from 'react';

const VoucherDetailsForm = ({ isEditMode, form, partners }) => {
  let partnerSelection = [
    {
      label: 'none',
      value: 'none',
      disabled: false
    }
  ];
  if (partners && partners.length > 0) {
    partnerSelection = partners;
  }

  return (
    <SectionContainer>
      <SectionCard title="Basic Information">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput label="Name" name="name" requiredErrorMessage="Type in partner's name." />
          </Col>
          <Col span={24} md={12}>
            <FormSelection
              label="Partner"
              name="partner"
              placeholder="Select partner"
              requiredErrorMessage="Please select voucher's partner."
              selections={partnerSelection}
              disabled={isEditMode}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInput label="Voucher URL" name="voucherUrl" requiredErrorMessage="Type in voucher url." type="url" />
          </Col>
          <Col span={24} md={24}>
            <FormInput label="Description" name="desc" />
          </Col>
          <Col span={24} md={12}>
            <FormDatePicker
              label="Start Date"
              name="startDate"
              extraProps={{
                allowClear: false
              }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormDatePicker
              label="End Date"
              name="endDate"
              extraProps={{
                allowClear: false
              }}
            />
          </Col>
        </Row>
      </SectionCard>
    </SectionContainer>
  );
};

export default VoucherDetailsForm;
