import api from './apiHelper';
import { queryCache } from 'react-query';
import { useCustomQuery, useCustomPaginatedQuery } from 'hooks/reactQuery';

const getMemberPath = params => `member${!!params ? `/${params}` : ''}`;

/* ---------------------------------- GET ---------------------------------------- */

export const useGetPaginatedMembers = (query, user, onError) => {
  if (user && user.role === 'mdecAdmin') {
    if (!query.filter) {
      query = {
        ...query,
        filter: {
          isMdecMember: true
        }
      };
    } else if (!query.filter.isMdecMember) {
      query.filter = { ...query.filter, isMdecMember: true };
    }
  }

  const getMembers = async (key, query) => {
    const path = getMemberPath('paginated');
    return api.get(path, { params: query });
  };

  return useCustomPaginatedQuery('members', getMembers, query, {
    onError
  });
};

export const useGetMemberById = (memberId, onError) => {
  const getMemberById = (key, memberId) => {
    const path = getMemberPath(memberId);
    return api.get(path);
  };
  return useCustomQuery('member', [memberId], getMemberById, { onError, enabled: !!memberId });
};

export const useGetMembersForSuggestions = (keyword = '') => {
  const getMembers = async (key, keyword) => {
    const path = getMemberPath('suggestions');
    return api.get(path, { params: { keyword } });
  };
  return useCustomQuery('getMembersForSuggestions', [keyword], getMembers);
};

/* ------------------------------- POST CREATE -------------------------------------- */

export const postCreateMember = payload => {
  const path = getMemberPath();
  return api.post(path, payload).then(newMember => {
    refetchMembers();
    return newMember;
  });
};

/* ---------------------------------- PATCH UPDATE ----------------------------------- */

export const patchUpdateMember = (memberId, payload) => {
  const path = getMemberPath(memberId);
  return api.patch(path, payload).then(updatedMember => {
    refetchMemberDetails(memberId);
    refetchMembers();
    return updatedMember;
  });
};

export const patchUpdateMemberStatus = (memberId, status) => {
  const path = getMemberPath(`${memberId}/status/${status}`);
  return api.patch(path).then(updatedMember => {
    refetchMemberDetails(memberId);
    refetchMembers();
    return updatedMember;
  });
};

/* ---------------------------------- DELETE ----------------------------------- */

export const deleteMember = memberId => {
  const path = getMemberPath(memberId);
  return api.delete(path).then(deletedMember => {
    refetchMembers();
    return deletedMember;
  });
};

/* ---------------------------------- REFETCH FUNCTION ---------------------------------------- */

const refetchMembers = () => queryCache.invalidateQueries(['members']);
const refetchMemberDetails = memberId => queryCache.invalidateQueries(['member', memberId]);
