import { Col, Row } from 'antd';
import FormInput from 'components/FormInput/FormInput';
import FormSinglePhoto from 'components/FormSinglePhoto/FormSinglePhoto';
import SectionCard from 'components/SectionCard/SectionCard';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import React from 'react';
import { FOLDER } from 'utils/constants';

const PartnerDetailsCard = ({ isEditMode, form }) => {
  return (
    <SectionContainer>
      <SectionCard title="Basic Information">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <FormInput label="Name" name="name" requiredErrorMessage="Type in partner's name." />
          </Col>
          <Col span={24} md={12}>
            <FormInput label="Website URL" name="websiteUrl" requiredErrorMessage="Type in partner's website URL." type="url" />
          </Col>
          <Col span={24} md={24}>
            <FormInput label="Description" name="description" />
          </Col>
          <Col span={24} md={12}>
            <FormSinglePhoto label="Upload Partner Logo" name="logoUrl" form={form} folderName={FOLDER.partner} limitOnePhoto={true} />
          </Col>
        </Row>
      </SectionCard>
    </SectionContainer>
  );
};

export default PartnerDetailsCard;
