import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Skeleton, message, Modal, Row } from 'antd';
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  DribbbleOutlined,
  DesktopOutlined,
  DeleteOutlined,
  SmileOutlined,
  HomeOutlined,
  TeamOutlined,
  CoffeeOutlined,
  CustomerServiceOutlined,
  SafetyOutlined,
  ShopOutlined,
  CarOutlined,
  WarningTwoTone
} from '@ant-design/icons';

import { useFetchConstant } from 'hooks/constants';
import { useGetPropertyDetailsById, postCreateProperty, patchUpdateProperty, deleteProperty } from 'apis/property';
import { useGetStaySuitesHosts } from 'apis/host';
import { formatToTimeMoment, formatToTimeString } from 'utils/date';
import { convertAmenitiesSelectionToObj, guard } from 'utils/general';
import { getPropertyDetailsRoute, getPropertiesRoute } from 'utils/routes';
import {
  FORM_KEY_CHECK_IN_TIME,
  FORM_KEY_CHECK_OUT_TIME,
  FORM_KEY_MIN_NIGHT,
  FORM_KEY_MAX_NIGHT,
  FORM_KEY_LOCATION,
  FORM_KEY_LONGITUDE,
  FORM_KEY_LATITUDE,
  FORM_KEY_PROPERTY_AMENITIES,
  FORM_KEY_IMAGES
} from './utils/formKey';

import BasicDetailsCard from './components/BasicDetailsCard/BasicDetailsCard';
import LocationCard from './components/LocationCard/LocationCard';
import AmenitiesCard from '../components/AmenitiesCard/AmenitiesCard';
import PhotosCard from '../components/PhotosCard/PhotosCard';

import { StyledSubmitButton } from './Property.styles';
import AdditionalDetailsCard from './components/AdditionalDetailsCard/AdditionalDetailsCard';

const { useForm } = Form;

/* ------------------------------------------- Other Function --------------------------------------------- */

const formatAmenitiesIntoSelections = propertyAmenities => {
  let formattedPropertyAmenities = {};
  Object.keys(propertyAmenities).forEach(key => {
    const { code, label, type } = propertyAmenities[key];

    if (type in formattedPropertyAmenities) {
      formattedPropertyAmenities[type].data.push({
        key: code,
        label,
        value: code
      });
    } else {
      formattedPropertyAmenities[type] = {
        icon: getPropertyAmenitiesIcon(type),
        data: [
          {
            key: code,
            label,
            value: code
          }
        ]
      };
    }
  });

  return formattedPropertyAmenities;
};

const getPropertyAmenitiesIcon = type => {
  const icons = {
    Activities: <DribbbleOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    'Business Facilities': <DesktopOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    'Cleaning Services': <SmileOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    'Common Area': <HomeOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    'Entertainment & Family Services': <TeamOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    'Food & Drink': <CoffeeOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    'Front Desk Services': <CustomerServiceOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    Miscellaneous: <SafetyOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    Shops: <ShopOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />,
    Transportation: <CarOutlined style={{ color: '#FFFFFF', paddingRight: '10px' }} />
  };
  return icons[type] || 'tool';
};

const formatPropertyPayload = values => {
  return {
    ...values,
    [FORM_KEY_PROPERTY_AMENITIES]: Object.values(values.propertyAmenities).reduce((amenities, currentAmmenity) => {
      return currentAmmenity === undefined ? amenities : amenities.concat(currentAmmenity);
    }, []),
    [FORM_KEY_LONGITUDE]: values[FORM_KEY_LOCATION][0],
    [FORM_KEY_LATITUDE]: values[FORM_KEY_LOCATION][1],
    [FORM_KEY_CHECK_IN_TIME]: formatToTimeString(values.checkInTime),
    [FORM_KEY_CHECK_OUT_TIME]: formatToTimeString(values.checkOutTime),
    ...(values.maxNight && { [FORM_KEY_MAX_NIGHT]: values.maxNight }),
    ...(values.minNight && { [FORM_KEY_MIN_NIGHT]: values.minNight }),
    ...(values.photos && {
      [FORM_KEY_IMAGES]: values.photos.map(photo => ({
        imageUrl: photo.imageUrl,
        caption: [{ text: photo.caption }]
      }))
    })
  };
};

/* ------------------------------------------- Fetch Function --------------------------------------------- */

const useFetchPropertyAmenities = () => {
  const { isLoading: isAmenitiesLoading, selection: amenitiesSelection } = useFetchConstant('propertyAmenities');

  const formattedAmenities = useMemo(() => {
    if (isAmenitiesLoading) {
      return {};
    }

    return formatAmenitiesIntoSelections(amenitiesSelection);
  }, [isAmenitiesLoading, amenitiesSelection]);

  return { isAmenitiesLoading, amenitiesSelection: formattedAmenities };
};

const useFetchHostSelection = isGetHasBookingOwnedHost => {
  const { isLoading: isHostLoading, data: hosts, isError } = useGetStaySuitesHosts(isGetHasBookingOwnedHost);

  const formattedHostSelection = useMemo(() => {
    if (!hosts || isHostLoading || isError) {
      return [];
    }

    return hosts.map(host => ({
      label: host.name,
      value: host._id,
      key: host._id
    }));
  }, [hosts, isHostLoading, isError]);

  return { hostSelection: formattedHostSelection, isHostLoading };
};

const useFetchPropertyDetails = (isAmenitiesLoading, listingId, amenitiesSelection) => {
  const {
    data: { propertyDetails },
    isLoading: isLoadingPropertyDetails,
    isError
  } = useGetPropertyDetailsById(listingId);

  const formattedPropertyDetails = useMemo(() => {
    if (!propertyDetails || isLoadingPropertyDetails || isAmenitiesLoading || isError) {
      return {};
    }

    return {
      ...propertyDetails,
      [FORM_KEY_CHECK_IN_TIME]: formatToTimeMoment(propertyDetails.checkInTime),
      [FORM_KEY_CHECK_OUT_TIME]: formatToTimeMoment(propertyDetails.checkOutTime),
      [FORM_KEY_LOCATION]: [propertyDetails.longitude, propertyDetails.latitude],
      [FORM_KEY_PROPERTY_AMENITIES]: convertAmenitiesSelectionToObj(propertyDetails.propertyAmenities, amenitiesSelection),
      photos: propertyDetails.images.map(image => ({
        imageUrl: image.imageUrl,
        caption: image.caption[0].text
      }))
    };
  }, [isLoadingPropertyDetails, isAmenitiesLoading, isError, propertyDetails, amenitiesSelection]);

  return {
    isLoadingPropertyDetails,
    propertyDetails: formattedPropertyDetails
  };
};

const useFetchConstants = () => {
  const { isLoading: isStateLoading, selection: statesSelection } = useFetchConstant('statesMY');
  const { isLoading: isCountriesLoading, selection: countriesSelection } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso3'
  });
  const { isLoading: isPropertyTypesLoading, selection: propertyTypesSelection } = useFetchConstant('propertyTypes');
  const { isLoading: isClassTypesLoading, selection: classTypesSelection } = useFetchConstant('classTypes');

  return {
    isLoading: isStateLoading || isCountriesLoading || isPropertyTypesLoading || isClassTypesLoading,
    selections: {
      statesSelection,
      countriesSelection,
      propertyTypesSelection,
      classTypesSelection
    }
  };
};

const Property = ({ listingId, isEditMode }) => {
  const [form] = useForm();
  const history = useHistory();

  const {
    selections: { statesSelection, countriesSelection, propertyTypesSelection, classTypesSelection },
    isLoading: isConstantsLoading
  } = useFetchConstants();
  const { isAmenitiesLoading, amenitiesSelection } = useFetchPropertyAmenities();

  const { isHostLoading, hostSelection } = useFetchHostSelection(!isEditMode);
  const { isLoadingPropertyDetails, propertyDetails } = useFetchPropertyDetails(isAmenitiesLoading, listingId, amenitiesSelection);

  const canEdit = useMemo(() => guard(() => !isEditMode || propertyDetails.canEdit, false), [isEditMode, propertyDetails]);

  const handleOnSubmitForm = async values => {
    try {
      const payload = formatPropertyPayload(values);

      Modal.confirm({
        title: `You are about to ${isEditMode ? 'update the' : 'create a new'} property`,
        content: 'You will not be able to undo this action, but you may update it later.',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          if (isEditMode) {
            patchUpdateProperty(listingId, payload).then(() => {
              message.success('You have successfully updated this property!');
            });
          } else {
            postCreateProperty(payload).then(newProperty => {
              message.success('You have successfully created new property!');
              history.push(getPropertyDetailsRoute(newProperty._id).path);
            });
          }
        },
        onCancel() {}
      });
    } catch (error) {
      message.error(`Error while ${isEditMode ? 'updating the property' : 'creating new property'}, please contact our support team!`);
      console.error(error.message);
    }
  };

  const handleOnSubmitFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name, { behavior: 'smooth' });
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  const handleOnDeleteButtonClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this property?',
      content: 'The property will be permanently deleted along with the room types in this property.',
      icon: <WarningTwoTone twoToneColor="red" />,
      okText: 'Confirm',
      onOk() {
        deleteProperty(listingId).then(() => {
          message.success('You have successfully deleted this property!');
          history.push(getPropertiesRoute().path);
        });
      },
      onCancel() {}
    });
  };

  const isLoading = isConstantsLoading || isAmenitiesLoading || isHostLoading || isLoadingPropertyDetails;

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[0, 16]}>
          <Form form={form} initialValues={propertyDetails} onFinish={handleOnSubmitForm} onFinishFailed={handleOnSubmitFailed}>
            <BasicDetailsCard
              isEditMode={isEditMode}
              canEdit={canEdit}
              form={form}
              hostSelection={hostSelection}
              propertyTypes={propertyTypesSelection}
              classTypesSelection={classTypesSelection}
            />
            <LocationCard canEdit={canEdit} form={form} states={statesSelection} countries={countriesSelection} />
            <AmenitiesCard canEdit={canEdit} fieldName={FORM_KEY_PROPERTY_AMENITIES} amenities={amenitiesSelection} />
            <PhotosCard form={form} />
            <AdditionalDetailsCard />
            <StyledSubmitButton type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
              {isEditMode ? 'Save' : 'Create'}
            </StyledSubmitButton>
            {isEditMode && (
              <Button type="danger" onClick={handleOnDeleteButtonClick} size="large" icon={<DeleteOutlined />}>
                Delete
              </Button>
            )}
          </Form>
        </Row>
      )}
    </>
  );
};

export default Property;
